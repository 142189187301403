import clsx from 'clsx';
import React from 'react';
import './ScreenLoader.scss';

const ScreenLoader = ({ fillSpace, boxLoader }) => {
  if (fillSpace) {
    return (
      <div className="screen-loader__filler">
        <div className="screen-loader">
          <img src="/images/checkout/loader-2.gif" alt="Loading..." loading="lazy" title="Loading..." />
        </div>
      </div>
    );
  }

  return (
    <div className={clsx('screen-loader', boxLoader && 'box-loader')}>
      <img src="/images/checkout/loader-2.gif" alt="Loading..." loading="lazy" title="Loading..." />
    </div>
  );
};

export default ScreenLoader;
