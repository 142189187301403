import { NetworkStatus } from 'apollo-client';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import ScreenLoader from '../components/ScreenLoader/ScreenLoader';
import { useUserContext } from '../context/UserContext';

const PrivateRoute = ({ children, ...rest }) => {
  const { user, userStatus } = useUserContext();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (userStatus === NetworkStatus.loading) {
          return <ScreenLoader fillSpace />;
        }

        if (user) {
          return children;
        }

        return (
          <Redirect
            to={{
              pathname: '/customer/login',
              search: `referrer=${location.pathname}`,
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
